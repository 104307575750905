import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const useHistoryMethod = () => {
  const { push, replace } = useRouter();
  return { push, replace };
};

export const useQueryParams = () => {
  const { query } = useRouter();
  return useMemo(() => query, [query]);
};

export const usePathname = () => {
  const { pathname } = useRouter();
  return pathname;
};
